<template>
    <div v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register')" class="footer_wrap mt-auto" ref="footer">
        <!-- Footer Start -->
        <div class="footer_wrap w-100" ref="footer">
            <div class="app-footer">
                <b-row align-h="around" class="">
                    <b-col cols="" class="position-relative col-auto">
                        <router-link tag="a" to="/web/homepage" class="nav-item-hold">
                            <!-- <div :class="{ 'nav-icon': true, 'active': $parent.path == 'homepage', 'inactive': $parent.path != 'homepage' }"> -->
                            <div class="box">
                                <img :src="$parent.path == 'homepage' ? require('./assets/images/flx_footer/home_active.png') : require('./assets/images/flx_footer/home.png')"
                                class="nav-icon"/>
                            </div>
                            <!-- </div> -->
                            <div :class="{ 'text-primary': $parent.path == 'homepage' }">
                                <span class="text-10 font-weight-bold">
                                    {{ $t('home') }}
                                </span>
                            </div>
                        </router-link>
                    </b-col>
                    <b-col cols="" class="col-auto">
                        <router-link tag="a" to="/web/trade" class="nav-item-hold">
                            <!-- <div :class="{ 'nav-icon': true, 'active': $parent.path == 'trade', 'inactive': $parent.path != 'trade' }"> -->
                            <div class="box">
                                <img :src="$parent.path == 'trade' ? require('./assets/images/flx_footer/package_active.png') : require('./assets/images/flx_footer/package.png')"
                                    class="nav-icon" />
                            </div>
                            <!-- </div> -->
                            <div :class="{ 'text-primary': $parent.path == 'trade' }">
                                <span class="text-10 font-weight-bold">
                                    {{ $t('package') }}
                                </span>
                            </div>
                        </router-link>
                    </b-col>
                    <b-col cols="" class="col-auto">
                        <div @click="toMall" class="nav-item-hold ">
                            <!-- <div :class="{ 'nav-icon': true, 'active': $parent.path == 'wallet', 'inactive': $parent.path != 'wallet' }"> -->
                            <div class="box">
                                <img :src="$parent.path == 'wallet' ? require('./assets/images/flx_footer/e_commerce_active.png') : require('./assets/images/flx_footer/e_commerce.png')"
                                class="nav-icon" />
                            </div>
                            <!-- </div> -->
                        </div>
                        <div :class="{ 'text-primary': $parent.path == 'wallet' }">
                            <span class="text-10 font-weight-bold">
                                {{ $t('mall') }}
                            </span>
                        </div>
                    </b-col>

                    <b-col cols="" class="col-auto">
                        <router-link tag="a" to="/web/ib_tree" class="nav-item-hold">
                            <!-- <div :class="{ 'nav-icon': true, 'active': $parent.path == 'ib_tree', 'inactive': $parent.path != 'ib_tree' }"> -->
                            <div class="box">
                                <img :src="$parent.path == 'ib_tree' ? require('./assets/images/flx_footer/member_tree_active.png') : require('./assets/images/flx_footer/member_tree.png')"
                                class="nav-icon" />
                            </div>
                            <!-- </div> -->
                            <div :class="{ 'text-primary': $parent.path == 'ib_tree' }">
                                <span class="text-10 font-weight-bold">
                                    {{ $t('network') }}
                                </span>
                            </div>
                        </router-link>
                    </b-col>

                    <!-- <b-col cols="" class="col-auto">
                        <router-link tag="a" to="/web/commission" class="nav-item-hold">
                            <div :class="{ 'nav-icon': true, 'active': $parent.path == 'commission', 'inactive': $parent.path != 'commission' }">
                                <img :src="$parent.path == 'commission' ? require('./assets/images/flx_footer/bonus_active.png') : require('./assets/images/flx_footer/bonus.png')"
                                    class="nav-icon" />
                            </div>
                        </router-link>
                    </b-col> -->
                </b-row>
                <!-- fotter end -->
            </div>
        </div>
    </div>

</template>
<script>
export default{
    methods:{
        toMall() {
            
            var token = localStorage.getItem('currencyToken');
            var lang = localStorage.getItem('lan');
            var baseUrl = window.location.hostname === 'jaboclub.com' ? "https://mall.jaboclub.com/web/sessions/login" : "https://uatmall.jaboclub.com/web/sessions/login";
            
            if (token) {
                var url = `${baseUrl}?key=${encodeURIComponent(token)}&lan=${encodeURIComponent(lang)}`;
                window.location.href = url;
            } else {
                console.error('Token not found in localStorage');
            }
        },
    }

}
</script>